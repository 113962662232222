<template>
  <section class="chat-area">
    <div v-if="isLoading" class="w-100">
      <b-spinner />
    </div>

    <div v-else-if="isChatEmpty">
      <b-img :src="placeholderImage" class="placeholder-img" center />
      <div class="mt-2 text-center">
        <p class="text-primary font-weight-500">
          {{ $t("chat.user-chat.empty-message") }}
        </p>
      </div>
    </div>

    <ChatMessages :messages="chatData" @msgToSend="sendMessage" />
  </section>
</template>

<script>
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
// import PlainEditor from '@core/components/editor/PlainEditor.vue';
import ChatPlaceholder from '@/assets/images/placeholders/light/chat.svg';
import ChatMessages from './ChatMessages.vue';

export default {
  name: 'ChatArea2',

  components: {
    // PlainEditor,
    ChatMessages,
  },

  mixins: [WidgetLayoutMixin],

  data() {
    return {
      // The name of the room with the roomType included
      isLoading: false,
    };
  },
  computed: {
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    placeholderImage() {
      return ChatPlaceholder;
    },
    room() {
      return this.$store.getters.currentCollective.slug;
    },
    chatData() {
      return this.$store.getters.chats[this.room]?.unpaginated || [];
    },
    isChatEmpty() {
      return this.chatData.length === 0;
    },
  },
  sockets: {
    async received(data) {
      if (data.room !== this.$store.getters.currentCollective.slug) {
        return;
      }
      await this.$store.dispatch('setItemSocket', {
        itemType: 'chats',
        storedKey: this.$store.getters.currentCollective.slug,
        item: data,
      });
    },
  },
  async created() {
    // Join chat room for the space:
    this.$socket.emit('join', this.$store.getters.currentCollective.slug);
    // Get messages from the previous room
    await this.fetchData();
  },
  methods: {
    sendMessage(inputMsg) {
      // console.log('EOEOOEOE: ', inputMsg)
      let chatInputMessageWithoutSpaces = inputMsg;
      const wordsToReplace = [' ', '<br>', '<p>', '</p>'];
      wordsToReplace.forEach((word) => {
        chatInputMessageWithoutSpaces = chatInputMessageWithoutSpaces.replaceAll(word, '');
      });
      // Don't send empty messages
      if (chatInputMessageWithoutSpaces.length === 0) {
        return;
      }
      // Save message
      this.$socket.emit('message', {
        communitySlug: this.$store.getters.currentCollective.slug,
        msg: inputMsg,
        memberKey: this.loggedMember.key,
      });
    },
    async fetchData() {
      this.isLoading = true;
      await this.$store.dispatch('getItemsSocket', {
        itemType: 'chats',
        storedKey: this.room ?? this.$store.getters.currentCollective.slug,
        perPage: 100,
        requestConfig: {
          room: this.room,
        },
      });
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.chat-area {
  height: calc(100% - 52px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.placeholder-img {
  width: 120px;
  display: block;
}
</style>
