<template>
  <div
    :class="{ 'full-height': formattedMessages.length }"
    :style="formattedMessages.length ? 'background-color: #f5f7fd' : ''"
  >
    <!-- <pre>{{ formattedMessages }}</pre> -->
    <!-- <pre>length: {{ messages.length }}</pre> -->
    <!-- <pre>{{ formattedMessages[4] }}</pre> -->
    <div v-if="formattedMessages.length" ref="chatMessagesBodyRef" class="chat-messages-body">
      <div
        v-for="dayMessages in formattedMessages"
        :key="dayMessages.day"
        class="chat-messages-day"
      >
        <div class="chat-messages-day-header text-center mb-1">
          <!-- {{ dayMessages.day }} -->
          <b-badge pill variant="primary">
            {{ $d(new Date(dayMessages.day), "long") }}
          </b-badge>
        </div>

        <ul class="list-unstyled chat-messages-list">
          <li
            v-for="(msgBySecond, i) in dayMessages.messagesBySecond"
            :key="msgBySecond.second + '-' + i"
            class="chat-message-by-second"
          >
            <!-- <pre>{{ i }} </pre> -->
            <div
              v-for="(messagesByUser, memberKey, index) in msgBySecond.messages"
              :key="memberKey + '-' + index"
              class="chat-message-user"
              :class="{ 'chat-message-user-right' : isLoggedMember(memberKey) }"
            >
              <!-- {{ memberKey }} -->
              <!-- <pre>{{ messagesByUser }}</pre> -->
              <div class="d-flex overflow-hidden">
                <div
                  class="user-avatar"
                  :class="{
                    'mr-1' : !isLoggedMember(memberKey),
                    'ml-1' : isLoggedMember(memberKey)
                  }"
                >
                  <!-- {{ !isLoggedMember(memberKey) ? 'otro' : 'yo' }} -->
                  <b-avatar variant="info" size="xs" :src="messagesByUser[0].memberData.avatarURL" />
                </div>
                <div class="chat-message-wrapper">
                  <div
                    v-for="(msg, index2) in messagesByUser"
                    :key="msg.memberData.key + '-' + index2"
                    class="chat-message-text"
                  >
                    <small v-if="messagesByUser[0] && !isLoggedMember(memberKey)" class="chat-message-text__user">
                      <!-- v-if="!isLoggedMember(memberKey) && index === 0 && index2 === 0"  -->
                      {{ msg.memberData.name }} {{ msg.memberData.surname }}
                    </small>
                    <!-- {{ msg.memberData.name }} - {{ msg.message }} -->
                    <div class="chat-message-text-html mb-0" v-html="msg.message" />
                    <!-- <div class="chat-message-text-html mb-0">
                      {{ msg.message }}
                    </div> -->
                    <div
                      v-if="messagesByUser[messagesByUser.length - 1]"
                      class="chat-message-text-time text-muted my-0"
                      :class="{ 'justify-content-end' : !isLoggedMember(memberKey) }"
                    >
                      <!-- <small>{{ msgBySecond.second }}</small> -->
                      <small>{{ msg.date | moment('HH:mm') }}</small>
                      <!-- <small v-if="!isLoggedMember(memberKey)">
                        - {{ msg.memberData.name }} {{ msg.memberData.surname }}
                      </small> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="chat-messages-footer">
      <b-form
        class="form-send-message d-flex justify-content-between align-items-center"
        @submit.prevent="sendMessage"
      >
        <b-form-input v-model="inputSendMsg" class="mr-1" placeholder="Type your message here" />
        <div class="message-actions d-flex align-items-center">
          <b-button variant="primary" type="submit">
            <span class="align-middle d-md-inline-block d-none">Send</span>
          </b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from "moment";

export default {
  name: 'ChatMessages',

  props: {
    messages: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      inputSendMsg: '',
    };
  },

  computed: {
    ...mapGetters(['loggedMember']),

    formattedMessages() {
      if (!this.messages.length) {
        return [];
      }

      let formattedMessages = [];
      formattedMessages = this.getFormattedMessages();

      // console.table('formattedMessages: ', formattedMessages);
      return formattedMessages;
    },
  },

  watch: {
    formattedMessages: {
      handler(newValue) {
        if (newValue.length) {
          this.$nextTick(() => {
            this.scrollToFinalChat();
          });
        }
      },
    },
  },

  methods: {
    isLoggedMember(key) {
      return this.loggedMember.key === key;
    },

    scrollToFinalChat() {
      // console.log(this.$refs.chatMessagesBodyRef);
      if (this.$refs.chatMessagesBodyRef) {
        this.$refs.chatMessagesBodyRef.scrollTop = this.$refs.chatMessagesBodyRef.scrollHeight;
      }
    },

    getFormattedMessages() {
      let messages = [];
      const objMessagesGroupByDay = this.messages.reduce(this.getMessagesGroupByDay, {});
      // console.log(Object.entries(result));
      const entriesByDay = Object.entries(objMessagesGroupByDay);

      messages = entriesByDay.map((e) => {
        const objMessagesGroupBySecond = e[1].reduce(this.getMessagesGroupBySecond, {});
        const entriesBySecond = Object.entries(objMessagesGroupBySecond);
        // console.log('entriesBySecond: ', entriesBySecond);
        const messagesBySecond = entriesBySecond.map((e2) => {
          // const allMessagesBySecond = e2[1].map((item) => console.log(item));
          // console.log('allMessagesBySecond: ', allMessagesBySecond);
          const objMessagesGroupByUser = e2[1].reduce(this.getMessagesGroupByUser, {});
          // console.log(objMessagesGroupByUser);

          return {
            second: e2[0],
            // isSameMinute: moment(e2[0].date).format("HH:mm"),
            messages: objMessagesGroupByUser,
          };
        });

        return {
          day: e[0],
          messagesBySecond,
        };
      });

      return messages;
    },

    getMessagesGroupByDay(x, y) {
      (x[moment(y.date).startOf("day").toISOString()] = x[moment(y.date).startOf("day").toISOString()] || []).push(y);
      return x;
    },

    getMessagesGroupByMinute(x, y) {
      (x[moment(y.date).format("HH:mm")] = x[moment(y.date).format("HH:mm")] || []).push(y);
      return x;
    },

    getMessagesGroupBySecond(x, y) {
      (x[moment(y.date).format("HH:mm:ss")] = x[moment(y.date).format("HH:mm:ss")] || []).push(y);
      return x;
    },

    getMessagesGroupByUser(x, y) {
      (x[y.memberData.key] = x[y.memberData.key] || []).push(y);
      return x;
    },

    sendMessage() {
      // console.log('sendMessage...', this.inputSendMsg);
      if (!this.inputSendMsg) {
        return;
      }
      this.$emit('msgToSend', this.inputSendMsg);
      this.inputSendMsg = '';
      this.scrollToFinalChat();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/base/core/colors/palette-variables.scss';

.chat-messages {
  border-radius: 0.375rem;
  &-body {
    height: calc(100% - 3.5rem);
    overflow: scroll;
    padding: 1rem;
  }
  &-footer {
    background-color: #fff;
    padding: 0.575rem 0.5rem;
  }
}

.chat-messages-list {
  .chat-message {
    // border: 1px solid blue;

    &-wrapper {
      padding: .5rem 0;
      .chat-message-text:last-child {
        .chat-message-text-time {
          display: flex;
          align-items: center;
        }
      }
    }

    &-user {
      // border: 1px solid green;
      display: flex;

      .user-avatar {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      .chat-message-text {
        border-radius: 0.375rem;
        padding: 0.5rem .75rem;
        width: fit-content;
        background-color: map-get($info-color, 'accent-2');
        box-shadow: 0 0.125rem 0.25rem rgba(165,163,174,.3);
        &:not(:last-child) {
          margin-bottom: .4rem;
        }

        &-html {
          > ::v-deep p {
            margin-bottom: 0 !important;
          }
        }
        &-time {
          display: none;
        }
      }

      &.chat-message-user-right {
        justify-content: flex-end;
        .user-avatar {
          order: 2;
        }
        .chat-message-text {
          color: #fff;
          background-color: map-get($primary-color, 'darken-1') !important;
        }
      }
    }
  }
}
</style>
